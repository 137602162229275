import React, { useEffect } from "react";

const PaymentIframe = () => {
  useEffect(() => {
    // const eventData = {
    //   type: 'custom_provider_ready',
    //   loaded: true
    // };
    // const customEvent = new CustomEvent('iframeLoaded', { detail: eventData });
    // window.dispatchEvent(customEvent);
    // console.log('Iframe is fully loaded and event dispatched with data:', eventData);


    window.parent.postMessage(
      JSON.stringify({
        type: 'custom_provider_ready',
        loaded: true,
      }),
      '*'
    )
    const handleMessages = (event) => {
      console.log("Received payment data:", event.data);
 
    };

    window.addEventListener('message', handleMessages);

   
  }, []);

  return (
    <div>
      <h2>Payment Url</h2>
    </div>
  );
};

export default PaymentIframe;
